import React from 'react'
import styled from 'styled-components'
import AspectRatio from './AspectRatio'

const AspectRatioWrapper = styled(AspectRatio)`
  max-width: ${({ mobile }) => mobile.width}px;
`

const VideoContainer = styled.div`
  overflow: hidden;
  display: inline-flex;
`

const margin = (props: VideoProps) => `
  calc(
    -1 * min(${props.maxWidth}px, 100%) * ${props.ratio} / ${props.cropFactor}
  )
`

interface VideoProps {
  maxWidth: number
  ratio: number
  cropFactor: number
}

const Video = styled.video<VideoProps>`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth}px;
  margin-top: ${margin};
  margin-bottom: ${margin};
  transform: scale(1.03);
`

interface RecoverBannerVideoProps {
  src: string
  type: string
  ratio: number
  cropFactor: number
  width: number
  height: number
  className?: string
  poster: string
}

const BannerVideoBackground: React.FC<RecoverBannerVideoProps> = ({
  width,
  height,
  ratio,
  cropFactor,
  src,
  type,
  className,
  poster,
}) => (
  <AspectRatioWrapper
    className={className}
    mobile={{ width, height }}
    desktop={{ width, height }}
  >
    <VideoContainer>
      <Video
        playsInline
        autoPlay
        loop
        muted
        maxWidth={width}
        ratio={ratio}
        cropFactor={cropFactor}
        poster={poster}
      >
        <source src={src} type={type} />
      </Video>
    </VideoContainer>
  </AspectRatioWrapper>
)

export default BannerVideoBackground
