import { AdditionalTiles } from '../models/AdditionalTile'

const recoverTiles: AdditionalTiles = [
  { image: '/images/services/1_recover_page/recover-01.jpg' },
  { image: '/images/services/1_recover_page/recover-02.jpg' },
  { image: '/images/services/1_recover_page/recover-03.jpg' },
  { image: '/images/services/1_recover_page/recover-04.jpg' },
]

export default recoverTiles
