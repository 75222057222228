import React from 'react'
import styled from 'styled-components'
import { RecoverTable as IRecoverTable } from '../data/recoverTable'
import Button from './Button'
import OnIcon from '../images/On.svg'
import OffIcon from '../images/Off.svg'
import FadeUpWhenVisible from './FadeUpWhenVisible'

const TdText = styled.td`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.body};
  font-size: 12px;
  line-height: 14px;
  padding: 10px 2px 10px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 13px;
    line-height: 15px;
    padding: 11px 17px;
  }
`

const TdIcon = styled.td`
  vertical-align: middle;
`

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Icon = styled.img`
  width: 16px;
  height: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 21px;
    height: 21px;
  }
`

interface CellProps {
  value: string | boolean
}

const Cell: React.FC<CellProps> = ({ value }) => {
  if (typeof value === 'string') {
    return <TdText>{value}</TdText>
  }

  return (
    <TdIcon>
      <IconWrapper>
        {value ? <Icon src={OnIcon} /> : <Icon src={OffIcon} />}
      </IconWrapper>
    </TdIcon>
  )
}

const RecoverTableWrapper = styled.div`
  padding: 0 15px 20px 15px;
`

const TableCard = styled.div`
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  max-width: 690px;
  margin: 0 auto;
  padding: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.25);
    border-radius: 13px;
    padding: 0;
  }
`

const Table = styled.table`
  width: 100%;
  table-layout: fixed;

  & thead th:first-child {
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      width: 195px;
    }
  }

  & thead tr,
  & tbody tr {
    border-bottom: 1px solid ${({ theme }) => theme.colors.muted};
  }

  & th:not(:last-child),
  & td:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.colors.muted};
  }
`

const Th = styled.th`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary};
  padding: 9px;
  vertical-align: middle;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 14px;
    font-size: 15px;
    line-height: 18px;
  }
`

const FooterCell = styled.span`
  display: inline-flex;
  padding: 4px 4px 0 4px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 9px;
  }
`

interface RecoverTableProps {
  table: IRecoverTable
}

const RecoverTable: React.FC<RecoverTableProps> = ({ table }) => (
  <RecoverTableWrapper>
    <FadeUpWhenVisible>
      <TableCard>
        <Table>
          <thead>
            <tr>
              {table.columns.map((c, i) => (
                <Th key={i}>{c.Header}</Th>
              ))}
            </tr>
          </thead>
          <tbody>
            {table.data.map((row, i) => (
              <tr key={i}>
                {table.columns.map((c, j) => (
                  <Cell key={j} value={row[c.accessor]} />
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              {table.columns.map((c, i) => (
                <th key={i}>
                  {c.FooterAction && (
                    <FooterCell>
                      <Button.Link size="small" to={c.FooterAction.url}>
                        {c.FooterAction.label}
                      </Button.Link>
                    </FooterCell>
                  )}
                </th>
              ))}
            </tr>
          </tfoot>
        </Table>
      </TableCard>
    </FadeUpWhenVisible>
  </RecoverTableWrapper>
)

export default RecoverTable
