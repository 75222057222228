import React from 'react'
import styled, { css } from 'styled-components'
import { AdditionalTiles as IAdditionalTiles } from '../models/AdditionalTile'
import theme from '../theme/theme'
import AspectRatio from './AspectRatio'
import FadeUpWhenVisible from './FadeUpWhenVisible'

const ratio = css`
  max-width: 148px;
  max-height: 148px;
  width: 100%;
  height: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 168px;
    max-height: 168px;
  }
`

const AspectRatioWrapper = styled(AspectRatio)`
  ${ratio}
`

const AdditionalTileWrapper = styled.li`
  ${ratio}
`

interface AdditionalTileLinkProps {
  image: string
  more?: 'true' | 'false'
  video?: 'true' | 'false'
}

const AdditionalTileSpan = styled.span<AdditionalTileLinkProps>`
  ${ratio};
  position: relative;
  background-image: url(${({ image }) => image});
  background-size: cover;
  border-radius: 5px;
  text-decoration: none;
  overflow: hidden;

  ${({ video }) =>
    video &&
    css`
      &:before {
        content: '';
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      &:after {
        content: '';
        background-image: url('/images/Play.svg');
        background-repeat: no-repeat;
        background-size: 52px 52px;
        background-position: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
          background-size: 76px 76px;
        }
      }
    `}

  ${({ more }) =>
    more &&
    css`
      &:after {
        content: '+';
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        color: white;
        font-weight: normal;
        font-size: 86px;
        line-height: 168px;
        text-align: center;
      }
    `}
`

interface AdditionalTileProps {
  image: string
  more?: boolean
  index: number
  type?: 'video'
}

const AdditionalTile: React.FC<AdditionalTileProps> = ({
  image,
  more,
  index,
  type,
}) => (
  <AdditionalTileWrapper>
    <FadeUpWhenVisible
      key={index}
      delay={(index + 1) * theme.transitions.fadeUp.delay}
    >
      <AspectRatioWrapper
        mobile={{ width: 168, height: 168 }}
        desktop={{ width: 168, height: 168 }}
      >
        <AdditionalTileSpan
          more={more ? 'true' : undefined}
          video={type === 'video' ? 'true' : undefined}
          image={image}
        />
      </AspectRatioWrapper>
    </FadeUpWhenVisible>
  </AdditionalTileWrapper>
)

const AdditionalTilesList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
  margin: 0 auto;
  max-width: 308px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 693px;
    gap: 7px;
  }
`

interface AdditionalTilesWrapperProps {
  responsiveOrder?: number
}

const AdditionalTilesWrapper = styled.div<AdditionalTilesWrapperProps>`

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 10px 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    ${({ responsiveOrder }) =>
      responsiveOrder !== undefined &&
      css`
        order: ${responsiveOrder * 2 - 1} !important;
      `}
  }
`

interface AdditionalTilesProps {
  items: IAdditionalTiles
  className?: string
  more?: boolean
  responsiveOrder?: number
}

const AdditionalTiles: React.FC<AdditionalTilesProps> = ({
  items,
  className,
  more,
  responsiveOrder,
}) => (
  <AdditionalTilesWrapper
    className={className}
    responsiveOrder={responsiveOrder}
  >
    <AdditionalTilesList>
      {items.map((item, index) => (
        <AdditionalTile
          index={index}
          key={index}
          image={item.image}
          more={more && index === items.length - 1}
          type={item.type}
        />
      ))}
    </AdditionalTilesList>
  </AdditionalTilesWrapper>
)

export default AdditionalTiles
