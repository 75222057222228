type Row = {
  name: string
  film: boolean
  glass: boolean
  reCover: boolean
}

type Column = {
  Header?: string
  FooterAction?: {
    label: string
    url: string
  }
  accessor: keyof Row
}

export type RecoverTable = {
  columns: Column[]
  data: Row[]
}

const columns: Column[] = [
  {
    accessor: 'name',
  },
  {
    Header: 'Film protection',
    accessor: 'film',
  },
  {
    Header: 'Verre trempé',
    accessor: 'glass',
  },
  {
    Header: 'reCover',
    accessor: 'reCover',
  },
]

const data: Row[] = [
  {
    name: 'Clarté',
    film: false,
    glass: true,
    reCover: true,
  },
  {
    name: 'Auto-cicatrisant',
    film: false,
    glass: false,
    reCover: true,
  },
  {
    name: 'Protection 360°',
    film: false,
    glass: false,
    reCover: true,
  },
  {
    name: 'Résistance',
    film: true,
    glass: true,
    reCover: true,
  },
  {
    name: 'Epaisseur',
    film: true,
    glass: true,
    reCover: true,
  },
  {
    name: 'Résistance traces de doigts',
    film: true,
    glass: false,
    reCover: true,
  },
  {
    name: 'Compatibilité écran incurvé',
    film: true,
    glass: false,
    reCover: true,
  },
  {
    name: 'Fait sur-mesure',
    film: false,
    glass: false,
    reCover: true,
  },
]

const recoverTable: RecoverTable = { columns, data }

export default recoverTable
