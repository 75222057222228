import React from 'react'
import { PageProps } from 'gatsby'
import AdditionalTiles from '../../components/AdditionalTiles'
import recoverTiles from '../../data/recoverTiles'
import BannerVideo from '../../components/BannerVideo'
import RecoverTable from '../../components/RecoverTable'
import recoverTable from '../../data/recoverTable'
import ServiceContent from '../../components/ServiceContent'
import { servicesBreadcrumbElement } from '.'
import { LayoutProps } from '../../components/Layout.context'
import Button from '../../components/Button'
import { magasinLink } from '../../data/menu'
import { Helmet } from 'react-helmet'

const title = 'reCover'

const ProtectionRecoverPage: React.FC<PageProps> = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          title="Une protection 2 en 1 pour vos smartphones "
          content="Découvrez les services de Protection et Récupération de Save : des solutions complètes pour sécuriser
           vos données et récupérer vos fichiers perdus.
          Protégez vos informations essentielles avec des solutions de pointe en récupération de données."
        />
      </Helmet>

      <BannerVideo
        title={title}
        description="La protection des héros !"
        width={690}
        height={321}
        ratio={852 / 480}
        cropFactor={1}
        src="/videos/Recover_Save.mp4"
        type="video/mp4"
        poster="/videos/Recover_Save_Moment.jpg"
      />
      <ServiceContent>
        <ServiceContent.Paragraph>
          Parce que les coques c'est bien mais c'est moche, Save vous propose
          une solution révolutionnaire ! Pour la découvrir rendez-vous vite dans
          nos magasins équipés !
        </ServiceContent.Paragraph>
        <ServiceContent.Title>
          Pourquoi reCover plutôt que n'importe quelle autre protection ?
        </ServiceContent.Title>
        <ServiceContent.Paragraph>
          Tout simplement parce que vous ne trouverez pas de protection plus
          subtile et plus ajustée que la reCover ! Plus souple qu'un verre
          trempé, la reCover ne se brise pas. Plus coriace qu'un simple film de
          protection, la reCover est super résistante. Alors si votre smartphone
          aime bien jouer les cascadeurs, notre protection reCover est faite
          pour vous !
        </ServiceContent.Paragraph>
        <ServiceContent.Title>Protection 2 en 1</ServiceContent.Title>
        <ServiceContent.Paragraph>
          Plus besoin de vous équiper d'une coque pour protéger l'arrière de
          votre smartphone, et en plus d'un film ou d'un verre trempé pour
          protéger votre écran, reCover s'occupe de tout ! Ajustée sur mesure,
          la protection reCover protège chaque parcelle de votre appareil :
          l'avant, l'arrière et mêmes les bords, c'est magique hein ?!
        </ServiceContent.Paragraph>
        <ServiceContent.Title>
          Quels sont les avantages de la reCover ?
        </ServiceContent.Title>
        <ServiceContent.Paragraph>
          Notre protection reCover a plein d'avantages, mais voici les
          principaux :
          <ServiceContent.List>
            <li>Elle absorbe les chocs</li>
            <li>
              Elle s'auto-guérit (oui oui, c'est vrai !) ce qui lui permet de
              rester belle comme au premier jour, sans trace de rayures !
            </li>
            <li>
              Elle protège vos appareils sans altérer le tactile ou la qualité
              d'écran
            </li>
            <li>
              Elle est découpée sur-mesure par nos experts et ira donc à
              merveille à votre smartphone, votre tablette ou montre connectée
            </li>
            <li>
              Elle est personnalisable : transparente pour être invisible, matte
              afin d'éviter les traces de doigts, ou personnalisable avec
              différentes maitères et motifs
            </li>
          </ServiceContent.List>
        </ServiceContent.Paragraph>
      </ServiceContent>
      <RecoverTable table={recoverTable} />
      <ServiceContent.ButtonContainer>
        <Button.Link to={`${magasinLink}?f%5Bservices%5D=33&f%5Brepairs%5D=`}>
          Je veux une reCover !
        </Button.Link>
      </ServiceContent.ButtonContainer>
      <AdditionalTiles more items={recoverTiles} />
    </>
  )
}

const layoutProps: LayoutProps = {
  title:
    'reCover, la protection à 360° pour votre smartphone, tablette ou montre connectée !',
  breadcrumb: [servicesBreadcrumbElement, { label: title }],
}

export default Object.assign(ProtectionRecoverPage, {
  layoutProps,
})
